<template>
  <v-dialog v-model="showDialog" persistent max-width="600">
    <v-card class="pb-3">
      <v-card-title class="justify-center text-uppercase">
        <h5>
          {{ getTitle }}
        </h5>
        <v-icon class="close-dialog" @click="onClose">
          mdi-close-circle-outline
        </v-icon>
      </v-card-title>
      <v-container>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit">
            <v-layout column class="px-4">
              <v-layout column>
                <label class="text-subtitle-1">
                  {{ $t("full_name") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="fullName"
                    outlined
                    dense
                    :filled="isDisabled"
                    :disabled="isDisabled"
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.employee_fullname')"
                  />
                </validation-provider>
              </v-layout>
              <!--email-->
              <v-layout column>
                <label class="text-body-2">
                  {{ `${$t('email')} ${$t('account').toLowerCase()} ` }}
                  <span class="red--text">{{ $t('required') }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  :rules="emailRules"
                >
                  <v-text-field
                    v-model="email"
                    outlined
                    dense
                    :filled="isDisabled"
                    :disabled="isDisabled"
                    class="rounded-lg"
                    :maxlength="maximumEmail"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.email')"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column>
                <label class="text-subtitle-1">
                  {{ $t("phone") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  :rules="phoneRules"
                >
                  <v-text-field
                    v-model="phone"
                    outlined
                    dense
                    :disabled="isDisabled"
                    class="rounded-lg"
                    :filled="isDisabled"
                    :maxlength="maximumPhone"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.phone')"
                    @keypress="isNumber($event)"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column>
                <label class="text-subtitle-1">
                  {{ $t("post_offices") }}
                </label>
                <v-text-field
                  v-if="postOfficeName"
                  v-model="postOfficeName"
                  :disabled="isDisabled"
                  class="rounded-lg"
                  :filled="isDisabled"
                  outlined
                  dense
                />
                <v-autocomplete
                  v-else
                  v-model="selectPostOffice"
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="isDisabled"
                  :filled="isDisabled"
                  :no-data-text="$t('no_data')"
                  item-text="name"
                  item-value="id"
                  :items="lstPostOffice"
                  :placeholder="$t('place_holders.choice-post-office')"
                />
              </v-layout>
              <v-layout v-if="isCreateNew" justify-end>
                <v-btn outlined class="mr-3" @click="onClose">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn color="primary" type="submit" :disabled="invalid">
                  {{ $t("create_admin") }}
                </v-btn>
              </v-layout>
              <v-layout v-else justify-end>
                <v-btn color="primary" outlined class="mr-3" @click="onActiveOrInactive">
                  {{ isActive ? $t('un_lock') : $t('lock') }}
                </v-btn>
              </v-layout>
            </v-layout>
          </v-form>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '@/constants'
import { StorageService } from '@/services/storageService'
import { PostService } from "@/services/postService";
import { FormUtils } from "@/helpers/formUtils";

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    },
    onAccept: {
      type: Function,
      default: null
    },
    onAcceptActiveOrInactive: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      useShippingMethod: this.$route.query.useShippingMethod,
      internalValue: this.value,
      partnerId: parseInt(this.$route.query.id),
      requiredRules: {
        required: true
      },
      isCreateNew: true,
      fullName: "",
      phone: "",
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone,
      },
      email: '',
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email
      },
      code: '',
      maximumSmallText: constants.maximumSmallText,
      key: '',
      branchId: '',
      typeSelected: '',
      methodSelected: '',
      serviceSelect: '',
      status: false,
      isActive: false,
      selectPostOffice: null,
      lstPostOffice: [],
      postOfficeName: ""
    }
  },
  computed: {
    getTitle() {
      return this.isCreateNew
        ? this.$t('create_admin')
        : this.$t('update_admin')
    },
    isDisabled() {
      return !this.isCreateNew
    },
  },
  watch: {
    item(val) {
      if (val) {
        this.isCreateNew = false
        this.email = val.email
        this.fullName = val.name
        this.phone = val.phone
        this.selectPostOffice = val.poId
        this.postOfficeName = val.poName
        if (val.status === constants.statusAdmin.active) {
          this.isActive = false;
        } else {
          this.isActive = true;
        }
        this.$forceUpdate()
      } else {
        this.isCreateNew = true
        this.clearData()
      }
    }
  },
  async created() {
    await this.searchPostOffice()
  },
  methods: {
    async onClose() {
      await this.clearData()
      this.$emit('close-dialog')
    },
    submit() {
      const pars = {
        name: this.fullName.trim(),
        phone: this.phone.trim(),
        email: this.email.trim(),
      }
      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice
      } else {
        pars["poId"] = StorageService.getPostOfficeId()
      }
      this.onAccept(pars)
    },
    async searchPostOffice() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await PostService.getPostOffice(pars);
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord;
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      }
      return pars
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
    onActiveOrInactive() {
      const pars = {
        id: this.item.id,
        status: this.item.status
      }
      this.onAcceptActiveOrInactive(pars)
    },
    async clearData() {
      this.email = ''
      this.fullName = ''
      this.phone = ''
      this.postOfficeName = ''
      this.selectPostOffice = null
      this.$refs.observer.reset()
    }
  }
}
</script>
<style lang="scss" scoped>
.close-dialog {
  position: absolute !important;
  right: 20px;
  top: 15px;
}
</style>
