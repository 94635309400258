var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"pb-3"},[_c('v-card-title',{staticClass:"justify-center text-uppercase"},[_c('h5',[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]),_c('v-icon',{staticClass:"close-dialog",on:{"click":_vm.onClose}},[_vm._v(" mdi-close-circle-outline ")])],1),_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{staticClass:"px-4",attrs:{"column":""}},[_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("full_name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"textRequired","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":_vm.isDisabled,"disabled":_vm.isDisabled,"maxlength":_vm.maximumSmallText,"error-messages":errors,"placeholder":_vm.$t('place_holders.employee_fullname')},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(((_vm.$t('email')) + " " + (_vm.$t('account').toLowerCase()) + " "))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('required')))])]),_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":_vm.isDisabled,"disabled":_vm.isDisabled,"maxlength":_vm.maximumEmail,"error-messages":errors,"placeholder":_vm.$t('place_holders.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("phone"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("required")))])]),_c('validation-provider',{attrs:{"name":"phone","rules":_vm.phoneRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","disabled":_vm.isDisabled,"filled":_vm.isDisabled,"maxlength":_vm.maximumPhone,"error-messages":errors,"placeholder":_vm.$t('place_holders.phone')},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('v-layout',{attrs:{"column":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("post_offices"))+" ")]),(_vm.postOfficeName)?_c('v-text-field',{staticClass:"rounded-lg",attrs:{"disabled":_vm.isDisabled,"filled":_vm.isDisabled,"outlined":"","dense":""},model:{value:(_vm.postOfficeName),callback:function ($$v) {_vm.postOfficeName=$$v},expression:"postOfficeName"}}):_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","disabled":_vm.isDisabled,"filled":_vm.isDisabled,"no-data-text":_vm.$t('no_data'),"item-text":"name","item-value":"id","items":_vm.lstPostOffice,"placeholder":_vm.$t('place_holders.choice-post-office')},model:{value:(_vm.selectPostOffice),callback:function ($$v) {_vm.selectPostOffice=$$v},expression:"selectPostOffice"}})],1),(_vm.isCreateNew)?_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":""},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("create_admin"))+" ")])],1):_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","outlined":""},on:{"click":_vm.onActiveOrInactive}},[_vm._v(" "+_vm._s(_vm.isActive ? _vm.$t('un_lock') : _vm.$t('lock'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }