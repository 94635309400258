<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout justify-end class="mb-2">
        <v-btn color="primary" @click="onShowCreateAdmin">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t("create_admin") }}
        </v-btn>
      </v-layout>
      <v-layout justify-space-between class="mb-3" style="align-items: flex-end">
        <v-layout class="col-6 pa-0">
          <v-layout column>
            <label class="text-subtitle-1">
              {{ `${$t('name')}/${$t('code')}` }}
            </label>
            <v-text-field
              v-model="objSearch"
              outlined
              dense
              class="mr-8"
              hide-details
              clearable
              style="max-width: fit-content;"
              prepend-inner-icon="mdi-magnify"
              :placeholder="`${$t('place_holders.name')}/${$t('code')}`"
              @keydown.enter="onClickSearch"
            />
          </v-layout>
          <v-layout column>
            <label class="text-subtitle-1">
              {{ $t("status") }}
            </label>
            <v-select
              v-model="activeOrinActive"
              outlined
              dense
              hide-details
              class="col-4"
              :items="lstOption"
              item-text="name"
              item-value="value"
              style="max-width: fit-content;"
              placeholder=""
            />
          </v-layout>
        </v-layout>
        <v-btn color="primary" class="col-2" @click="onClickSearch">
          {{ $t("search") }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        @click:row="viewDetail"
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.name }}
          </td>
        </template>
        <template v-slot:[`item.postOffice`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.poName }}
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ getTextStatus(item.status) }}
          </td>
        </template>
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>
    </v-layout>
    <dialog-create-or-update
      ref="dialogCreateOrUpdate"
      :show-dialog="onShowDialogCreateOrUpdate"
      :item="selectItem"
      :on-accept="onCreateAdmin"
      :on-accept-active-or-inactive="onAcceptActiveOrInactive"
      @close-dialog="closeDialog"
    />
    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :persistent="persistent"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import DialogCreateOrUpdate from "@/views/employee/DialogCreateOrUpdate"
import CustomPagination from "@/components/CustomPagination.vue";
import constants from "@/constants";
import { StringUtils } from "@/helpers/stringUtils";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { UserService } from "@/services/userService";
import { DataUtils } from "@/helpers/dataUtils";
import { StorageService } from "@/services/storageService";
import { mapActions } from "vuex";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    CustomPagination,
    DialogCreateOrUpdate,
    Breadscrumbs
  },
  data() {
    return {
      objSearch: "",
      headers: [
        {
          text: this.$t("employee_name"),
          value: "name",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("employee_code"),
          value: "code",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("sdt"),
          value: "phone",
          sortable: false,
          class: "text-subtitle-2",
        },
        { text: this.$t("email"), value: "email", class: "text-subtitle-2" },
        {
          text: this.$t("post_offices"),
          value: "postOffice",
          sortable: false,
          class: "text-subtitle-2",
        },
        { text: this.$t("status"), value: "status", class: "text-subtitle-2" },
      ],
      lstOption: [
        {
          name: 'Tất cả',
          value: null
        },
        {
          name: 'Hoạt động',
          value: 1
        },
        {
          name: "Đã khóa",
          value: 0
        }
      ],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      persistent: false,
      showLoading: false,
      showConfirmTokenExpried: false,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
      breadcrumbs: [
        {
          text: 'list_admin',
          disabled: true,
          isActive: true,
        },
      ],
      onShowDialogCreateOrUpdate: false,
      selectItem: null,
      dataCreateOrUpdate: null,
      activeOrinActive: null,
    };
  },
  computed: {
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.numberEmployeesPerPage + 1;
      } else {
        return 0;
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.numberEmployeesPerPage + this.items.length;
      } else {
        return (this.page - 1) * constants.numberEmployeesPerPage;
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  watch: {},
  created() {
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") || this.currentPoId !== 2) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    async initData() {
      this.search();
    },
    async search() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await UserService.searchManage(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord;
        this.totalPage = DataUtils.calculateEmployeePage(data.totalRecord);
        if (data.totalRecord > 0) {
          this.items = data.data;
        } else {
          this.items = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        currentPage: this.page,
        limit: constants.numberEmployeesPerPage,
        objSearch: "",
        status: this.activeOrinActive
      };

      if (this.objSearch) {
        pars.objSearch = this.objSearch.trim();
      }

      return pars;
    },
    async onCreateAdmin(par) {
      this.dataCreateOrUpdate = par
      if (this.dataCreateOrUpdate) {
        const pars = this.dataCreateOrUpdate
        this.showLoading = true
        const { status, data } = await UserService.createManage(pars)
        if (status === constants.statusCode.ok && data) {
          this.onShowDialogCreateOrUpdate = false
          this.showLoading = false
          this.toggleDialogNoti({
            state: true,
            persistent: true,
            type: constants.typeAlert.success,
            msg: this.$t("success_admin_create"),
          });
          setTimeout(() => {
            window.location.reload()
          }, constants.timeOut);
          this.$refs.dialogCreateOrUpdate.clearData()
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
            this.showLoading = false
          }, constants.timeOut)
        }
      }
    },
    async onAcceptActiveOrInactive(par) {
      var userActive = null
      const pars = {
        id: par.id
      }
      if (par.status === constants.statusAdmin.inactive) {
        userActive = await UserService.activeManage(pars)
      } else {
        userActive = await UserService.inactiveManage(pars)
      }
      const { status, data } = userActive
      if (status === constants.statusCode.ok && data) {
        this.onShowDialogCreateOrUpdate = false
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: par.status === constants.statusAdmin.inactive ? this.$t("msg-active-success") : this.$t("msg-inactive-success"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          window.location.reload()
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    },
    bindStatusCodes(items) {
      const results = [];
      items.forEach((item) => {
        results.push(item.code);
      });
      return results;
    },
    closeDialog() {
      this.selectItem = null
      this.onShowDialogCreateOrUpdate = false
    },
    onShowCreateAdmin() {
      this.selectItem = null
      this.onShowDialogCreateOrUpdate = true
    },
    onClickSearch() {
      this.page = 1;
      this.search();
    },
    onChangePage(page) {
      this.page = page;
      this.search();
    },
    getTextStatus(item) {
      if (item === constants.statusAdmin.active) {
        return this.$t('actived')
      }
      return this.$t('locked')
    },
    displayRole(val) {
      if (val === constants.roleRoot) {
        return "Admin root";
      }
      if (val === constants.roleAdmin) {
        return "Admin";
      }
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "", persistent: false, }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
      this.persistent = pars.persistent;
    },
    viewDetail(item) {
      this.selectItem = item
      this.onShowDialogCreateOrUpdate = true
    },
  },
};
</script>
<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
